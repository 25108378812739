import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import * as React from "react";
import {styled} from "@mui/material/styles";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import {CurrentMonthActivity} from "../../model/CurrentMonthActivity";
import {convertSecondsToHoursAndMinutes} from "../../TimeUtils";
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Cancel} from "@mui/icons-material";
import {ChangeEvent, useState} from "react";
import {CurrentMonthGroup} from "../../dashboard/component/CurrentMonthGroupsComponent";

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    }
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

interface CurrentMonthActivitiesTableComponentProps {
    currentMonthActivities: CurrentMonthActivity[],
    handleGroupChange: (groups: Map<string, CurrentMonthGroup>) => void;
    currentMonthGroups: Map<string, CurrentMonthGroup>;
    height: string | number
}

export default function CurrentMonthActivitiesTableComponent(props: CurrentMonthActivitiesTableComponentProps) {
    const [selectedRows, setSelectedRows] = useState<CurrentMonthActivity[]>([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogTextValue, setDialogTextValue] = useState('');
    const [dialogError, setDialogError] = useState('');

    const handleAddToGroup = () => {
        setOpenDialog(true);
    }

    const handleDeleteFromGroup = (group: CurrentMonthGroup, row: CurrentMonthActivity) => {
        const updatedRowsInGroups = new Map(props.currentMonthGroups);
        const updatedGroup = group;
        const updatedActivities = updatedGroup.activities.filter(activity => activity.issue !== row.issue);
        if (updatedActivities.length > 0) {
            updatedGroup.activities = updatedActivities;
            updatedRowsInGroups.set(updatedGroup.groupName, updatedGroup);
        } else {
            updatedRowsInGroups.delete(updatedGroup.groupName);
        }
        props.handleGroupChange(updatedRowsInGroups);
    }

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>, row: CurrentMonthActivity) => {
        if (event.target.checked) {
            setSelectedRows([...selectedRows, row]);
        } else {
            setSelectedRows(selectedRows.filter(selectedRow => selectedRow !== row));
        }
    };

    const handleDialogTextChange = (event: ChangeEvent<HTMLInputElement>) => {
        setDialogTextValue(event.target.value);
        setDialogError('');
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setDialogTextValue('');
    };

    const handleDialogCreateButton = () => {
        if (dialogTextValue.trim() !== '') {
            const updatedCurrentMonthGroups = new Map(props.currentMonthGroups);
            if (updatedCurrentMonthGroups.has(dialogTextValue)) {
                const existingGroupList = updatedCurrentMonthGroups.get(dialogTextValue);
                if (existingGroupList) {
                    const updatedGroup = existingGroupList;
                    updatedGroup.activities = [...updatedGroup.activities, ...selectedRows];
                    updatedCurrentMonthGroups.set(dialogTextValue, updatedGroup);
                }
            } else {
                const newGroup = {} as CurrentMonthGroup;
                newGroup.groupName = dialogTextValue;
                newGroup.activities = selectedRows;
                newGroup.addedSeconds = 0;
                updatedCurrentMonthGroups.set(dialogTextValue, newGroup);
            }
            props.handleGroupChange(updatedCurrentMonthGroups);
            setSelectedRows([]);
            handleDialogClose();
        } else {
            setDialogError('Nazwa grupy nie może być pusta!')
        }
    };

    const handleDialogKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            handleDialogCreateButton();
        }
    };

    return (
        <TableContainer component={Paper} style={{maxHeight: props.height}}>
            <Dialog open={openDialog} onClose={handleDialogClose} onKeyDown={handleDialogKeyDown}>
                <DialogTitle>Dodaj do nowej lub istniejącej grupy</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Nazwa grupy"
                        fullWidth
                        value={dialogTextValue}
                        onChange={handleDialogTextChange}
                        error={!!dialogError}
                        helperText={dialogError}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Anuluj
                    </Button>
                    <Button onClick={handleDialogCreateButton} color="primary">
                        Utwórz
                    </Button>
                </DialogActions>
            </Dialog>
            <Table sx={{minWidth: 700}}>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Zgłoszenie</StyledTableCell>
                        <StyledTableCell>Podsumowanie</StyledTableCell>
                        <StyledTableCell align="right">Czas</StyledTableCell>
                        <StyledTableCell align="center">
                            <Button variant={selectedRows.length === 0 ? "outlined" : "contained"}
                                    onClick={handleAddToGroup} disabled={selectedRows.length === 0}>
                                Grupa +
                            </Button>
                        </StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.currentMonthActivities.map((row) => {
                        const rowGroup = Array.from(props.currentMonthGroups.values()).find((group) => group.activities.some(activity => activity.issue === row.issue));
                        return (
                            <StyledTableRow key={row.issue}>
                                <StyledTableCell component="th" scope="row">
                                    <a href={row.url}>{row.issue}</a>
                                </StyledTableCell>
                                <StyledTableCell>{row.description}</StyledTableCell>
                                <StyledTableCell
                                    align="right">{convertSecondsToHoursAndMinutes(row.totalTimeSpentSeconds)}</StyledTableCell>
                                <StyledTableCell align="center">
                                    {rowGroup ? <Box>
                                            {rowGroup.groupName}<IconButton onClick={handleDeleteFromGroup.bind(null, rowGroup, row)}><Cancel
                                            color="error"/></IconButton>
                                        </Box> :
                                        <Checkbox
                                            color="primary"
                                            checked={selectedRows.includes(row)}
                                            onChange={event => handleCheckboxChange(event, row)}/>}
                                </StyledTableCell>
                            </StyledTableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
