import * as React from 'react';
import Grid from '@mui/material/Grid';
import {Paper} from "@mui/material";
import FileUpToDateComponent from "./component/FileUpToDateComponent";
import HoursBalanceComponent from "../hours/component/HoursBalanceComponent";
import CurrentMonthActivitiesTableComponent from "../activities/component/CurrentMonthActivitiesTableComponent";
import Typography from "@mui/material/Typography";
import DashboardTimeComponent from "./component/DashboardTimeComponent";
import Box from "@mui/material/Box";
import {TimesheetViewerSummary} from "../model/TimesheetViewerSummary";
import NoDataComponent from "../component/NoDataComponent";
import {useEffect} from "react";
import {CurrentMonthActivity} from "../model/CurrentMonthActivity";
import CurrentMonthGroupsComponent, {BonusGroup, CurrentMonthGroup} from "./component/CurrentMonthGroupsComponent";

interface DashboardProps {
    summary: TimesheetViewerSummary | null,
    changeTitle: (title: string) => void,
    currentMonthGroups: Map<string, CurrentMonthGroup>,
    handleGroupsChange: (groups: Map<string, CurrentMonthGroup>) => void,
    bonusGroup: BonusGroup | null,
    handleBonusGroupChange: (newBonusGroup: BonusGroup | null) => void
}

export default function DashboardPage(props: DashboardProps) {
    useEffect(() => {
        props.changeTitle("Panel główny");
    });
    return (props.summary ? CreateDashboardPageWithData(props.summary, props.currentMonthGroups, props.handleGroupsChange, props.bonusGroup, props.handleBonusGroupChange) : <NoDataComponent/>);
}

function areAllActivitiesInGroups(currentMonthActivities: CurrentMonthActivity[], currentMonthGroups: Map<string, CurrentMonthGroup>): boolean {
    return currentMonthActivities.length === Array.from(currentMonthGroups.values()).reduce((totalActivities, currentMonthGroup) =>
        totalActivities + currentMonthGroup.activities.length, 0)
}

function CreateDashboardPageWithData(summary: TimesheetViewerSummary,
                                     activityGroups: Map<string, CurrentMonthGroup>,
                                     handleGroupChange: (groups: Map<string, CurrentMonthGroup>) => void,
                                     bonusGroup: BonusGroup | null,
                                     handleBonusGroupChange: (newBonusGroup: BonusGroup | null) => void) {
    return <Grid container spacing={3}>
        <Grid item xs={12}>
            <Paper
                sx={{
                    p: 1,
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Typography
                    variant="caption"
                    color="text.secondary"
                    noWrap
                    sx={{flexGrow: 1}}
                >
                    Status zaimportowanych plików
                </Typography>
                <FileUpToDateComponent timesheetStatistics={summary.timesheetStatistics}/>
            </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
            <Paper
                sx={{
                    p: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240
                }}
            >
                <Typography
                    variant="caption"
                    color="text.secondary"
                    noWrap
                    sx={{flexGrow: 1}}
                >
                    Bilans godzin
                </Typography>
                <HoursBalanceComponent seconds={summary.hours.balanceSeconds}/>
            </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={9}>
            <Box height={240}>
                <DashboardTimeComponent balanceSeconds={summary.hours.balanceSeconds}
                                        overtimes={summary.hours.overtimeDaysToTake}
                                        shortTimes={summary.hours.shortTimeDaysToMadeUp}/>
            </Box>
        </Grid>
        {activityGroups.size > 0 ? <Grid item xs={12}>
            <Paper
                sx={{
                    p: 1,
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Typography
                    variant="caption"
                    color="text.secondary"
                    noWrap
                    sx={{flexGrow: 1}}
                >
                    Utworzone grupy
                </Typography>
                <CurrentMonthGroupsComponent currentMonthGroups={activityGroups}
                                             allElementsAssignedToGroup={areAllActivitiesInGroups(summary.activities.currentMonthActivities, activityGroups)}
                                             expectedCurrentMonthActivitiesTotalSeconds={summary.activities.expectedCurrentMonthActivitiesTotalSeconds}
                                             handleGroupChange={handleGroupChange}
                                             height="auto" bonusGroup={bonusGroup} handleBonusGroupChange={handleBonusGroupChange}/>
            </Paper>
        </Grid> : ""}
        <Grid item xs={12}>
            <Paper
                sx={{
                    p: 1,
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Typography
                    variant="caption"
                    color="text.secondary"
                    noWrap
                    sx={{flexGrow: 1}}
                >
                    Aktywności z bieżącego miesiąca
                </Typography>
                <CurrentMonthActivitiesTableComponent currentMonthActivities={summary.activities.currentMonthActivities}
                                                      height="auto" handleGroupChange={handleGroupChange} currentMonthGroups={activityGroups}/>
            </Paper>
        </Grid>
    </Grid>
}
