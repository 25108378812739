import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";

interface ShortTimeComponentProps {
    text: string
}

export default function ShortTimeComponent(props: ShortTimeComponentProps) {
    return (
        <React.Fragment>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
                width="100%"
            >
                <Box display="flex">
                    <Typography
                        variant="h3"
                        color="red"
                        noWrap
                    >
                        {props.text}
                    </Typography>
                </Box>
            </Box>
        </React.Fragment>
    );
}