import Grid from "@mui/material/Grid";
import DropzoneComponent from "./DropzoneComponent";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress
} from "@mui/material";
import * as React from "react";
import {useState} from "react";
import Typography from "@mui/material/Typography";
import {FileObject} from "mui-file-dropzone";
import axios, {AxiosResponse} from "axios";
import {TimesheetViewerSummary} from "../model/TimesheetViewerSummary";

interface FileUploadComponentProps {
    callback: (summary: TimesheetViewerSummary) => void
}

const BACKEND_ENDPOINT = "https://timesheet-viewer-back.onrender.com";
const PAID_VACATION_UPLOAD_ENDPOINT = BACKEND_ENDPOINT + "/process/paid-vacation";
const UNPAID_VACATION_UPLOAD_ENDPOINT = BACKEND_ENDPOINT + "/process/unpaid-vacation";

function handleTemplateFileDownload(path: string, fileName: string) {
    fetch(path)
        .then(response => response.blob())
        .then(blob => {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
            window.URL.revokeObjectURL(link.href);
        });
}

export default function FileUploadComponent(props: FileUploadComponentProps) {
    const [worklogsFile, setWorklogsFile] = useState<FileObject | null>(null);
    const [vacationsFile, setVacationsFile] = useState<FileObject | null>(null);
    const [companyHolidaysFile, setCompanyHolidaysFile] = useState<FileObject | null>(null);
    const [loading, setLoading] = useState(false);
    const [dialogErrorMessage, setDialogErrorMessage] = useState<string | null>(null);

    const handleWorklogsFileTemplateDownload = () => {
        handleTemplateFileDownload(process.env.PUBLIC_URL + "/file-templates/worklogs_file_template.csv", "worklogs.csv")
    }

    const handleVacationsFileTemplateDownload = () => {
        handleTemplateFileDownload(process.env.PUBLIC_URL + "/file-templates/vacations_file_template.csv", "vacations.csv")
    }

    const handleCompanyHolidaysFileTemplateDownload = () => {
        handleTemplateFileDownload(process.env.PUBLIC_URL + "/file-templates/company_holidays_file_template.csv", "company_holidays.csv")
    }

    const handleSetResponse = (response: AxiosResponse | void) => {
        if (response) {
            props.callback(response.data);
        }
    }

    const handleWorklogsFileUpload = (file: FileObject) => {
        setWorklogsFile(file);
    }

    const handleVacationsFileUpload = (file: FileObject) => {
        setVacationsFile(file);
    }

    const handleCompanyHolidaysFileUpload = (file: FileObject) => {
        setCompanyHolidaysFile(file);
    }

    const handleWorklogsFileDelete = () => {
        setWorklogsFile(null);
    }

    const handleVacationsFileDelete = () => {
        setVacationsFile(null);
    }

    const handleCompanyHolidaysFileDelete = () => {
        setCompanyHolidaysFile(null);
    }

    const handleButtonClick = async () => {
        if (worklogsFile && vacationsFile && companyHolidaysFile) {
            const formData = new FormData();
            formData.append("worklogsFile", worklogsFile.file);
            formData.append("vacationsFile", vacationsFile.file);
            formData.append("companyHolidaysFile", companyHolidaysFile.file);

            setLoading(true)
            handleSetResponse(await axios.post(PAID_VACATION_UPLOAD_ENDPOINT, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            }).catch(error => {
                console.error(error);
                setLoading(false);
                setDialogErrorMessage("Wystąpił nieoczekiwany błąd podczas analizy danych!");
            }))
            setLoading(false);
            setWorklogsFile(null);
            setVacationsFile(null);
            setCompanyHolidaysFile(null);
        } else if (worklogsFile) {
            const formData = new FormData();
            formData.append("worklogsFile", worklogsFile.file);

            setLoading(true)
            handleSetResponse(await axios.post(UNPAID_VACATION_UPLOAD_ENDPOINT, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            }).catch(error => {
                console.error(error);
                setLoading(false);
                setDialogErrorMessage("Wystąpił nieoczekiwany błąd podczas analizy danych!");
            }))
            setLoading(false);
            setWorklogsFile(null);
        }
    };

    const areAllFilesUploaded = worklogsFile || (worklogsFile && vacationsFile && companyHolidaysFile);

    return (<>
            <Dialog open={!!dialogErrorMessage}>
                <DialogTitle color="red">Wystąpił błąd!</DialogTitle>
                <DialogContent>
                    <DialogContentText>{dialogErrorMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogErrorMessage(null)}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            {loading ?
                <LinearProgress color={"secondary"} sx={{
                    mt: 3,
                    mb: 3
                }}/> :
                <Grid container spacing={1}>
                    <Grid item xs={12} md={8} lg={4} sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 500,
                        height: 300
                    }}>
                        <Typography variant="body1" color="inherit" align="center">
                            Timesheet
                        </Typography>
                        <DropzoneComponent onFileUpload={handleWorklogsFileUpload}
                                           onFileDelete={handleWorklogsFileDelete}
                                           onTemplateFileDownload={handleWorklogsFileTemplateDownload}/>
                    </Grid>
                    <Grid item xs={12} md={8} lg={4} sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 500,
                        height: 300
                    }}>
                        <Typography variant="body1" color="inherit" align="center">
                            Urlopy
                        </Typography>
                        <DropzoneComponent onFileUpload={handleVacationsFileUpload}
                                           onFileDelete={handleVacationsFileDelete}
                                           onTemplateFileDownload={handleVacationsFileTemplateDownload}/>
                    </Grid>
                    <Grid item xs={12} md={8} lg={4} sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 500,
                        height: 300
                    }}>
                        <Typography variant="body1" color="inherit" align="center">
                            Firmowe dni wolne od pracy
                        </Typography>
                        <DropzoneComponent onFileUpload={handleCompanyHolidaysFileUpload}
                                           onFileDelete={handleCompanyHolidaysFileDelete}
                                           onTemplateFileDownload={handleCompanyHolidaysFileTemplateDownload}/>
                    </Grid>
                    <Grid item xs={12} sx={{
                        p: 2,
                        mt: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <div>
                            <Button variant={areAllFilesUploaded ? "contained" : "outlined"} onClick={handleButtonClick}
                                    disabled={!areAllFilesUploaded}>
                                Rozpocznij analizę
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            }
        </>
    )
}