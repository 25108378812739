import * as React from 'react';
import {Route, Routes} from 'react-router-dom'
import {NavigationValue} from "./NavigationValues";

interface AppRouterProps {
    navigationPaneValues: NavigationValue[]
}

export default function AppRouter(props: AppRouterProps) {
    return (
        <Routes>
            {props.navigationPaneValues.map(item => <Route key={item.path} path={item.path} element={item.page}/>)}
        </Routes>
    )
}
