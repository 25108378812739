import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";
import FileUploadComponent from "./component/FileUploadComponent";
import Grid from "@mui/material/Grid";
import {TimesheetViewerSummary} from "./model/TimesheetViewerSummary";
import {Paper} from "@mui/material";
import DataRetrievedComponent from "./component/DataRetrievedComponent";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

interface HomePageProps {
    dataCallback: (summary: TimesheetViewerSummary) => void,
    dataRetrieved: boolean,
    changeTitle: (title: string) => void
}

export default function HomePage(props: HomePageProps) {
    useEffect(() => {
        props.changeTitle("Start");
    });
    const navigate = useNavigate();
    const handleDataCallback = (summary: TimesheetViewerSummary) => {
        props.dataCallback(summary);
        navigate("/dashboard");
    }
    return props.dataRetrieved ? <DataRetrievedComponent/> : createHomePageWhenDataIsEmpty(handleDataCallback);
}

function createHomePageWhenDataIsEmpty(dataCallback: (summary: TimesheetViewerSummary) => void) {
    return <Box
        display="flex"
        height="100%"
        width="100%"
    >
        <Grid container spacing={3}>
            <Grid item xs={12} sx={{
                justifyContent: "center",
                alignItems: "center", display: "flex"
            }}>
                <Box display="flex">
                    <Typography
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{flexGrow: 1}}
                    >
                        Witaj w aplikacji <b>Jira Timesheet Viewer</b>!
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} sx={{
                justifyContent: "center",
                alignItems: "center", display: "flex"
            }}>
                <Box display="flex">
                    <Typography
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{flexGrow: 1}}
                    >
                        Prześlij pliki do analizy, aby rozpocząć korzystanie z serwisu.
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Paper
                    sx={{
                        p: 1,
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Typography
                        variant="caption"
                        color="text.secondary"
                        noWrap
                        sx={{flexGrow: 1}}
                    >
                        Jak wygenerować timesheet?
                    </Typography>
                    <Typography
                        variant="body1"
                        color="inherit"
                        noWrap
                        sx={{
                            justifyContent: "center",
                            alignItems: "center", display: "flex"
                        }}
                    >
                        Na stronie timesheetu wyszukaj dane za pomocą poniższego JQL, wybierz zakładkę&nbsp;<b>Advanced</b>&nbsp;i wyeksportuj plik CSV.
                    </Typography>
                    <Box mt={1}
                         sx={{
                             justifyContent: "center",
                             alignItems: "center", display: "flex",
                         }}>
                        <Typography
                            variant="body1"
                            color="white"
                            fontFamily="Monaco, monospace"
                            noWrap
                            padding={0.5}
                            sx={{backgroundColor: "black"}}
                        >
                            worklogAuthor = 712020:a301bfa7-d90f-4f4d-8f17-80ea4903b7fc AND timespent is not EMPTY
                            order
                            by created DESC
                        </Typography>
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper
                    sx={{
                        p: 1,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Typography
                        variant="caption"
                        color="text.secondary"
                        noWrap
                        sx={{flexGrow: 1, mb: 2.5}}
                    >
                        Prześlij pliki do analizy
                    </Typography>
                    <FileUploadComponent callback={dataCallback}/>
                </Paper>
            </Grid>
        </Grid>
    </Box>
}