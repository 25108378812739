import React, {useState} from 'react';
import {DropzoneAreaBase, FileObject} from 'mui-file-dropzone';
import {FilePresent, Delete} from '@mui/icons-material';
import {Button} from "@mui/material";
import Grid from "@mui/material/Grid";

interface Props {
    onFileUpload: (file: FileObject) => void;
    onFileDelete: () => void;
    onTemplateFileDownload: () => void;
}

export default function DropzoneComponent(props: Props) {
    const [file, setFile] = useState<FileObject>();

    const handleFileChange = (file: FileObject) => {
        setFile(file);
        props.onFileUpload(file);
    };

    const handleFileDelete = () => {
        setFile(undefined)
        props.onFileDelete();
    };

    const handleTemplateFileDownload = () => {
        props.onTemplateFileDownload();
    }

    return (
        file ?
            <React.Fragment>
                <FilePresent fontSize="large"/>
                <div>
                    {file.file.name}
                    <Delete fontSize="small" onClick={handleFileDelete}/>
                </div>
            </React.Fragment>
            : <Grid container spacing={1}>
                <Grid item xs={12} sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <DropzoneAreaBase
                        fileObjects={file ? [file] : []}
                        acceptedFiles={['.csv']}
                        showPreviews={true}
                        maxFileSize={5000000} // 5 MB
                        filesLimit={1}
                        onAdd={(file) => handleFileChange(file[0])}
                        dropzoneText="Przeciągnij i upuść plik albo kliknij"
                    />
                </Grid>
                <Grid item xs={12} sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Button fullWidth variant="contained" onClick={handleTemplateFileDownload}>
                        Pobierz szablon
                    </Button>
                </Grid>
            </Grid>
    )
}