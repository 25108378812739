import Grid from "@mui/material/Grid";
import {Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import VacationComponent from "./component/VacationComponent";
import CompanyHolidaysComponent from "./component/CompanyHolidaysComponent";
import PublicHolidaysComponent from "./component/PublicHolidaysComponent";
import VacationTableComponent from "./component/VacationTableComponent";
import CompanyHolidaysTableComponent from "./component/CompanyHolidaysTableComponent";
import PublicHolidaysTableComponent from "./component/PublicHolidaysTableComponent";
import {Holidays} from "../model/Holidays";
import NoDataComponent from "../component/NoDataComponent";
import {useEffect} from "react";

interface HolidaysPageProps {
    holidays: Holidays | undefined,
    changeTitle: (title: string) => void
}

export default function HolidaysPage(props: HolidaysPageProps) {
    useEffect(() => {
        props.changeTitle("Dni wolne od pracy");
    });
    return props.holidays ? createHolidaysPageWithData(props.holidays) : <NoDataComponent/>;
}

function createHolidaysPageWithData(holidays: Holidays) {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} md={4} lg={4}>
                <Paper
                    sx={{
                        p: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 120
                    }}>
                    <Typography
                        variant="caption"
                        color="text.secondary"
                        noWrap
                        sx={{flexGrow: 1}}>
                        Wykorzystany urlop
                    </Typography>
                    <VacationComponent vacations={holidays.vacations} vacationLimitDays={holidays.vacationLimitDays}/>
                </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
                <Paper
                    sx={{
                        p: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 120
                    }}>
                    <Typography
                        variant="caption"
                        color="text.secondary"
                        noWrap
                        sx={{flexGrow: 1}}>
                        Ilość firmowych dni wolnych
                    </Typography>
                    <CompanyHolidaysComponent companyHolidays={holidays.companyHolidays}/>
                </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
                <Paper
                    sx={{
                        p: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 120
                    }}>
                    <Typography
                        variant="caption"
                        color="text.secondary"
                        noWrap
                        sx={{flexGrow: 1}}>
                        Ilość świąt
                    </Typography>
                    <PublicHolidaysComponent publicHolidays={holidays.publicHolidays}/>
                </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={6}>
                <Paper
                    sx={{
                        p: 1,
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Typography
                        variant="caption"
                        color="text.secondary"
                        noWrap
                        sx={{flexGrow: 1}}
                    >
                        Urlopy
                    </Typography>
                    <VacationTableComponent vacations={holidays.vacations}/>
                </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={6}>
                <Paper
                    sx={{
                        p: 1,
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Typography
                        variant="caption"
                        color="text.secondary"
                        noWrap
                        sx={{flexGrow: 1}}
                    >
                        Firmowe dni wolne od pracy
                    </Typography>
                    <CompanyHolidaysTableComponent companyHolidays={holidays.companyHolidays}/>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper
                    sx={{
                        p: 1,
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Typography
                        variant="caption"
                        color="text.secondary"
                        noWrap
                        sx={{flexGrow: 1}}
                    >
                        Święta
                    </Typography>
                    <PublicHolidaysTableComponent publicHolidays={holidays.publicHolidays}/>
                </Paper>
            </Grid>
        </Grid>
    );
}