import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import * as React from "react";
import {styled} from "@mui/material/styles";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import {PublicHoliday} from "../../model/PublicHoliday";
import {formatIsoDateToString} from "../../TimeUtils";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

interface PublicHolidaysTableComponentProps {
    publicHolidays: PublicHoliday[]
}

export default function PublicHolidaysTableComponent(props: PublicHolidaysTableComponentProps) {
    return (
        <TableContainer component={Paper}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Nazwa święta</StyledTableCell>
                        <StyledTableCell align="right">Data rozpoczęcia</StyledTableCell>
                        <StyledTableCell align="right">Data zakończenia</StyledTableCell>
                        <StyledTableCell align="right">Ilość dni</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.publicHolidays.map((row) => (
                        <StyledTableRow key={row.startDate}>
                            <StyledTableCell component="th" scope="row">{row.name}</StyledTableCell>
                            <StyledTableCell align="right">{formatIsoDateToString(row.startDate)}</StyledTableCell>
                            <StyledTableCell align="right">{formatIsoDateToString(row.endDate)}</StyledTableCell>
                            <StyledTableCell align="right">{row.nonWeekendDaysCount}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}