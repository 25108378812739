import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from "@mui/material/List";
import {Link} from 'react-router-dom'
import {NavigationValue} from "../NavigationValues";

interface NavigationPanelProps {
    navigationPaneValues: NavigationValue[]
}

export default function NavigationPane(props: NavigationPanelProps) {
    return (
        <List component="nav">
            <React.Fragment>
                {
                    props.navigationPaneValues.map(item => (
                        <ListItemButton component={Link} to={item.path} key={item.path}>
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.text}/>
                        </ListItemButton>
                    ))
                }
            </React.Fragment>
        </List>
    );
}
