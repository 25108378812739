import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Grid from "@mui/material/Grid";

export default function DataRetrievedComponent() {
    return (
        <Box
            display="flex"
            height="100%"
            width="100%"
        >
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{
                    justifyContent: "center",
                    alignItems: "center", display: "flex"
                }}>
                    <Box display="flex">
                        <Typography
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{flexGrow: 1}}
                        >
                            <b>Dane są już przetworzone!</b>
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sx={{
                    justifyContent: "center",
                    alignItems: "center", display: "flex"
                }}>
                    <Box display="flex">
                        <Typography
                            variant="body1"
                            color="inherit"
                            noWrap
                            sx={{flexGrow: 1}}
                        >
                            Możesz korzystać z serwisu używając panelu bocznego. <a href="/">Odśwież stronę</a>, jeśli chcesz przeanalizować nowe dane.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}