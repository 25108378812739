import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import * as React from "react";
import {styled} from "@mui/material/styles";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import {convertHoursAndMinutesToSeconds, convertSecondsToHours, convertSecondsToHoursAndMinutes} from "../../TimeUtils";
import {CurrentMonthActivity} from "../../model/CurrentMonthActivity";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    TableFooter,
    Typography
} from "@mui/material";
import {Add, AttachMoney, Edit, Print, Remove, RestartAlt} from "@mui/icons-material";
import {useState} from "react";
import CurrentMonthGroupsDialogComponent, {CurrentMonthGroupsDialogProps} from "./CurrentMonthGroupsDialogComponent";

const StyledTableRow = styled(TableRow)(() => ({
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

const BonusGroupRow = styled(TableRow)(() => ({
    backgroundColor: "palegoldenrod"
}));

const StyledTable = styled(Table)(() => ({
    "& .MuiTableCell-root": {
        border: '1px solid grey',
        padding: "5px",
        fontSize: "11pt"
    }
}));

const ExportTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(even)': {
        '& > *': {
            backgroundColor: 'white',
        }
    },
    '&:nth-of-type(odd)': {
        '& > *': {
            backgroundColor: '#f2f2f2',
        }
    },
}));

function calculateTotalSecondsOfActivitiesInGroup(currentMonthGroup: CurrentMonthGroup): number {
    return currentMonthGroup.activities.reduce((totalGroupSeconds, currentMonthActivityInGroup) => {
        return totalGroupSeconds + currentMonthActivityInGroup.totalTimeSpentSeconds;
    }, 0);
}

function calculateTotalSecondsOfActivities(currentMonthGroups: Map<string, CurrentMonthGroup>): number {
    return Array.from(currentMonthGroups).reduce((totalSeconds, currentMonthGroup) => {
        return totalSeconds + calculateTotalSecondsOfActivitiesInGroup(currentMonthGroup[1]);
    }, 0);
}

function calculateTotalAddedSeconds(currentMonthGroups: Map<string, CurrentMonthGroup>): number {
    return Array.from(currentMonthGroups).reduce((totalSeconds, currentMonthGroup) => {
        return totalSeconds + currentMonthGroup[1].addedSeconds;
    }, 0);
}

function checkIfAllConditionsMet(props: CurrentMonthGroupsComponentProps) {
    return props.allElementsAssignedToGroup &&
        props.expectedCurrentMonthActivitiesTotalSeconds ===
        calculateTotalSecondsOfActivities(props.currentMonthGroups) + calculateTotalAddedSeconds(props.currentMonthGroups);
}

export interface CurrentMonthGroup {
    groupName: string,
    activities: CurrentMonthActivity[],
    addedSeconds: number
}

export interface BonusGroup {
    groupName: string,
    addedSeconds: number,
    bonusPercent: number
}

interface CurrentMonthGroupsComponentProps {
    currentMonthGroups: Map<string, CurrentMonthGroup>
    handleGroupChange: (newCurrentMonthGroups: Map<string, CurrentMonthGroup>) => void,
    bonusGroup: BonusGroup | null,
    handleBonusGroupChange: (newBonusGroup: BonusGroup | null) => void,
    allElementsAssignedToGroup: boolean,
    expectedCurrentMonthActivitiesTotalSeconds: number,
    height: string | number
}

export default function CurrentMonthGroupsComponent(props: CurrentMonthGroupsComponentProps) {
    const [dialogProps, setDialogProps] = useState<CurrentMonthGroupsDialogProps>({
        editGroup: null,
        dialogTitle: "",
        dialogContentText: "",
        dialogButtonText: "",
        dialogGroupLabel: "",
        dialogInputAdornmentText: "",
        dialogGroupPlaceholder: "",
        dialogTimeLabel: "",
        dialogTimePlaceholder: "",
        dialogBonusPercentLabel: "",
        dialogBonusPercentPlaceholder: "",
        dialogCallback: () => {},
        onDialogTimeTextChange: () => {}
    });
    const [exportDialogShow, setExportDialogShow] = useState(false);

    const handleAddSecondsButton = (group: CurrentMonthGroup) => {
        setDialogProps({
            editGroup: group,
            dialogTitle: "Dodaj czas do grupy",
            dialogContentText: group.groupName + ": " + convertSecondsToHoursAndMinutes(calculateTotalSecondsOfActivitiesInGroup(group) + group.addedSeconds),
            dialogButtonText: "Dodaj",
            dialogInputAdornmentText: "+",
            dialogTimeLabel: "Czas",
            dialogTimePlaceholder: "HHh MMm",
            dialogCallback: (currentProps, dialogGroupTextValue, dialogTimeTextValue) => handleAddSeconds(currentProps, dialogTimeTextValue),
            onDialogTimeTextChange: (currentProps, dialogTimeTextValue) => onDialogTextChangeAdd(currentProps, dialogTimeTextValue)
        })
    };

    const handleSubtractSecondsButton = (group: CurrentMonthGroup) => {
        setDialogProps({
            editGroup: group,
            dialogTitle: "Odejmij czas grupy",
            dialogTimeLabel: "Czas",
            dialogInputAdornmentText: "-",
            dialogTimePlaceholder: "HHh MMm",
            dialogContentText: group.groupName + ": " + convertSecondsToHoursAndMinutes(calculateTotalSecondsOfActivitiesInGroup(group) + group.addedSeconds),
            dialogButtonText: "Odejmij",
            dialogCallback: (currentProps, dialogGroupTextValue, dialogTimeTextValue) => handleSubtractSeconds(currentProps, dialogTimeTextValue),
            onDialogTimeTextChange: (currentProps, dialogTimeTextValue) => onDialogTextChangeSubtract(currentProps, dialogTimeTextValue)
        })
    };

    const handleResetAddedSecondsButton = (group: CurrentMonthGroup) => {
        setDialogProps({
            editGroup: group,
            dialogTitle: "Czy jesteś pewien?",
            dialogContentText: "Czy na pewno chcesz zresetować dodany czas do grupy " + group.groupName + "?",
            dialogButtonText: "Tak",
            dialogCallback: (currentProps) => handleResetSeconds(currentProps)
        });
    };

    const handleGroupNameEditButton = (group: CurrentMonthGroup) => {
        setDialogProps({
            editGroup: group,
            dialogTitle: "Edytuj nazwę grupy",
            dialogGroupLabel: "Nowa nazwa grupy",
            dialogContentText: "Aktualna nazwa: " + group.groupName,
            dialogButtonText: "Edytuj",
            dialogCallback: (currentProps, dialogGroupTextValue) => handleGroupNameEdit(currentProps, dialogGroupTextValue)
        });
    }

    const handleResetAllButton = () => {
        setDialogProps({
            editGroup: {} as CurrentMonthGroup,
            dialogTitle: "Czy jesteś pewien?",
            dialogContentText: "Czy na pewno chcesz zresetować wszystkie zmiany?",
            dialogButtonText: "Tak",
            dialogCallback: () => handleResetAll()
        });
    };

    const handleAddBonusGroupButton = () => {
        setDialogProps({
            editGroup: {} as CurrentMonthGroup,
            dialogTitle: "Tworzenie grupy z dodatkowym wynagrodzeniem",
            dialogButtonText: "Utwórz",
            dialogGroupLabel: "Nazwa grupy",
            dialogInputAdornmentText: "%",
            dialogBonusPercentLabel: "Wymiar dodatkowego wynagrodzenia",
            dialogCallback: (currentProps, dialogGroupTextValue, dialogTimeTextValue, dialogBonusPercentTextValue) => handleAddBonusGroup(dialogGroupTextValue, dialogBonusPercentTextValue),
        })
    };

    const handleGroupDownloadButton = () => {
        setExportDialogShow(true);
    }

    const handleAddSeconds = (currentProps: CurrentMonthGroupsDialogProps, dialogTimeTextValue: string) => {
        if (currentProps.editGroup) {
            const updatedCurrentMonthGroups = props.currentMonthGroups;
            const updatedGroup = updatedCurrentMonthGroups.get(currentProps.editGroup.groupName);
            if (updatedGroup) {
                updatedGroup.addedSeconds = updatedGroup.addedSeconds + convertHoursAndMinutesToSeconds(dialogTimeTextValue);
                updatedCurrentMonthGroups.set(currentProps.editGroup.groupName, updatedGroup);
                props.handleGroupChange(updatedCurrentMonthGroups);
                onDialogClose();
            }
        }
    };

    const onDialogTextChangeAdd = (currentProps: CurrentMonthGroupsDialogProps, dialogTimeTextValue: string) => {
        if (currentProps.editGroup) {
            const updatedDialogProps = {...currentProps};
            updatedDialogProps.dialogContentText = currentProps.editGroup.groupName + ": " + convertSecondsToHoursAndMinutes(calculateSecondsAfterAdd(currentProps.editGroup, dialogTimeTextValue));
            setDialogProps(updatedDialogProps);
        }
    };


    const handleSubtractSeconds = (currentProps: CurrentMonthGroupsDialogProps, dialogTimeTextValue: string) => {
        if (currentProps.editGroup) {
            const updatedCurrentMonthGroups = props.currentMonthGroups;
            const updatedGroup = updatedCurrentMonthGroups.get(currentProps.editGroup.groupName);
            if (updatedGroup) {
                if (calculateSecondsAfterSubtract(currentProps.editGroup, dialogTimeTextValue)) {
                    updatedGroup.addedSeconds = updatedGroup.addedSeconds - convertHoursAndMinutesToSeconds(dialogTimeTextValue);
                } else {
                    updatedGroup.addedSeconds = -1 * currentProps.editGroup.activities.reduce((totalSeconds, activity) => {
                        return totalSeconds + activity.totalTimeSpentSeconds
                    }, 0)
                }
                updatedCurrentMonthGroups.set(currentProps.editGroup.groupName, updatedGroup);
                props.handleGroupChange(updatedCurrentMonthGroups);
                onDialogClose();
            }
        }
    };

    const onDialogTextChangeSubtract = (currentProps: CurrentMonthGroupsDialogProps, dialogTimeTextValue: string) => {
        if (currentProps.editGroup) {
            const updatedDialogProps = {...currentProps};
            updatedDialogProps.dialogContentText = currentProps.editGroup.groupName + ": " + convertSecondsToHoursAndMinutes(calculateSecondsAfterSubtract(currentProps.editGroup, dialogTimeTextValue));
            setDialogProps(updatedDialogProps);
        }
    };

    const onDialogClose = () => {
        setDialogProps({
            editGroup: null,
            dialogTitle: "",
            dialogButtonText: "",
            dialogCallback: () => {}
        });
    }

    const handleResetSeconds = (currentProps: CurrentMonthGroupsDialogProps) => {
        if (currentProps.editGroup) {
            const updatedCurrentMonthGroups = new Map(props.currentMonthGroups);
            const updatedGroup = updatedCurrentMonthGroups.get(currentProps.editGroup.groupName);
            if (updatedGroup) {
                updatedGroup.addedSeconds = 0;
                updatedCurrentMonthGroups.set(currentProps.editGroup.groupName, updatedGroup);
                props.handleGroupChange(updatedCurrentMonthGroups);
                onDialogClose();
            }
        }
    };

    const handleGroupNameEdit = (currentProps: CurrentMonthGroupsDialogProps, dialogGroupTextValue: string) => {
        if (currentProps.editGroup) {
            const updatedCurrentMonthGroups = new Map(props.currentMonthGroups);
            const updatedGroup = updatedCurrentMonthGroups.get(currentProps.editGroup.groupName);
            if (updatedGroup) {
                updatedCurrentMonthGroups.delete(currentProps.editGroup.groupName);
                const existingGroupWithNewName = updatedCurrentMonthGroups.get(dialogGroupTextValue);
                if (existingGroupWithNewName) {
                    existingGroupWithNewName.activities = [...existingGroupWithNewName.activities, ...updatedGroup.activities];
                    existingGroupWithNewName.addedSeconds = existingGroupWithNewName.addedSeconds + updatedGroup.addedSeconds;
                    updatedCurrentMonthGroups.set(dialogGroupTextValue, existingGroupWithNewName);
                } else {
                    updatedGroup.groupName = dialogGroupTextValue;
                    updatedCurrentMonthGroups.set(dialogGroupTextValue, updatedGroup);
                }
                props.handleGroupChange(updatedCurrentMonthGroups);
                onDialogClose();
            }
        }
    }

    const handleResetAll = () => {
        const updatedCurrentMonthGroups = new Map(props.currentMonthGroups);
        if (updatedCurrentMonthGroups) {
            updatedCurrentMonthGroups.forEach(currentMonthGroup => currentMonthGroup.addedSeconds = 0);
            props.handleGroupChange(updatedCurrentMonthGroups);
            props.handleBonusGroupChange(null);
            onDialogClose();
        }
    };

    const handleAddBonusGroup = (dialogGroupTextValue: string, dialogBonusPercentTextValue: string) => {
        const bonusGroup = {} as BonusGroup;
        bonusGroup.groupName = dialogGroupTextValue;
        bonusGroup.bonusPercent = parseInt(dialogBonusPercentTextValue)
        bonusGroup.addedSeconds = bonusGroup.bonusPercent / 100 * props.expectedCurrentMonthActivitiesTotalSeconds;
        props.handleBonusGroupChange(bonusGroup);
        onDialogClose();
    };

    const calculateSecondsAfterAdd = (group: CurrentMonthGroup, dialogTimeTextValue: string): number => {
        return group.activities.reduce((totalSeconds, activity) => {
            return totalSeconds + activity.totalTimeSpentSeconds
        }, 0) + group.addedSeconds + convertHoursAndMinutesToSeconds(dialogTimeTextValue)
    };

    const calculateSecondsAfterSubtract = (group: CurrentMonthGroup, dialogTimeTextValue: string): number => {
        const newValue = group.activities.reduce((totalSeconds, activity) => {
            return totalSeconds + activity.totalTimeSpentSeconds
        }, 0) + group.addedSeconds - convertHoursAndMinutesToSeconds(dialogTimeTextValue);
        return newValue > 0 ? newValue : 0;
    };

    const activitiesTotalSeconds = calculateTotalSecondsOfActivities(props.currentMonthGroups);
    const addedTotalSeconds = calculateTotalAddedSeconds(props.currentMonthGroups);
    let secondsLeft = props.expectedCurrentMonthActivitiesTotalSeconds - (addedTotalSeconds + activitiesTotalSeconds);
    const areAllConditionsMet = checkIfAllConditionsMet(props);

    const StyledTableCell = styled(TableCell)(({theme}) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: areAllConditionsMet ? theme.palette.success.main : theme.palette.error.main,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    return (
        <>
            <CurrentMonthGroupsDialogComponent props={dialogProps} onCancelButtonClick={onDialogClose}/>
            <Dialog open={exportDialogShow} fullWidth maxWidth="md">
                <DialogContent>
                    <TableContainer>
                        <StyledTable id="export-table">
                            <TableHead>
                                <TableRow>
                                    <TableCell width="40" align="center" valign="middle"><b>LP.</b></TableCell>
                                    <TableCell align="center" valign="middle"><b>OPIS USŁUG</b></TableCell>
                                    <TableCell width="170" align="center" valign="middle"><b>LICZBA GODZIN</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Array.from(props.currentMonthGroups).map(([x, currentMonthGroup], index) => {
                                    return (<ExportTableRow key={index}>
                                        <TableCell width="40" align="center" valign="middle">{index + 1}.</TableCell>
                                        <TableCell valign="middle">{currentMonthGroup.groupName}</TableCell>
                                        <TableCell width="170"
                                                   align="center" valign="middle">{convertSecondsToHours(currentMonthGroup.addedSeconds + currentMonthGroup.activities.reduce((totalSeconds, activity) => {
                                            return totalSeconds + activity.totalTimeSpentSeconds;
                                        }, 0))}</TableCell>
                                    </ExportTableRow>);
                                })
                                }
                                {props.bonusGroup ? <ExportTableRow>
                                    <TableCell width="40"
                                               align="center" valign="middle">{props.currentMonthGroups.size + 1}.</TableCell>
                                    <TableCell valign="middle">{props.bonusGroup.groupName}</TableCell>
                                    <TableCell width="170"
                                               align="center" valign="middle">{convertSecondsToHours(props.bonusGroup.addedSeconds)}</TableCell>
                                </ExportTableRow> : ""
                                }
                            </TableBody>
                        </StyledTable>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setExportDialogShow(false)}>
                        Zamknij
                    </Button>
                </DialogActions>
            </Dialog>
            <TableContainer component={Paper} style={{height: props.height}}>
                <Table sx={{minWidth: 700}} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Nazwa grupy</StyledTableCell>
                            <StyledTableCell align="right">Czas z aktywności</StyledTableCell>
                            <StyledTableCell align="right">Czas dodany</StyledTableCell>
                            <StyledTableCell align="right">Czas łącznie</StyledTableCell>
                            <StyledTableCell align="right">
                                <IconButton onClick={handleAddBonusGroupButton}>
                                    <AttachMoney sx={{color: "#ffffff"}}/>
                                </IconButton>
                                <IconButton onClick={handleResetAllButton}>
                                    <RestartAlt sx={{color: "#ffffff"}}/>
                                </IconButton>
                                <IconButton onClick={handleGroupDownloadButton} disabled={!areAllConditionsMet}>
                                    <Print sx={areAllConditionsMet ? {color: "white"} : {color: "grey"}}/>
                                </IconButton>
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.from(props.currentMonthGroups).map(([groupKey, currentMonthGroup]) => {
                            const activitiesGroupTotalSeconds = currentMonthGroup.activities.reduce((totalSeconds, currentMonthActivity) => {
                                return totalSeconds + currentMonthActivity.totalTimeSpentSeconds;
                            }, 0);
                            return (<StyledTableRow key={groupKey}>
                                <StyledTableCell>{currentMonthGroup.groupName}</StyledTableCell>
                                <StyledTableCell
                                    align="right">{convertSecondsToHoursAndMinutes(activitiesGroupTotalSeconds)}</StyledTableCell>
                                <StyledTableCell
                                    align="right">{convertSecondsToHoursAndMinutes(currentMonthGroup.addedSeconds)}</StyledTableCell>
                                <StyledTableCell
                                    align="right">{convertSecondsToHoursAndMinutes(activitiesGroupTotalSeconds + currentMonthGroup.addedSeconds)}</StyledTableCell>
                                <StyledTableCell align="right">
                                    <IconButton onClick={handleAddSecondsButton.bind(null, currentMonthGroup)}>
                                        <Add/>
                                    </IconButton>
                                    <IconButton onClick={handleSubtractSecondsButton.bind(null, currentMonthGroup)}>
                                        <Remove/>
                                    </IconButton>
                                    <IconButton onClick={handleResetAddedSecondsButton.bind(null, currentMonthGroup)}>
                                        <RestartAlt/>
                                    </IconButton>
                                    <IconButton onClick={handleGroupNameEditButton.bind(null, currentMonthGroup)}>
                                        <Edit/>
                                    </IconButton>
                                </StyledTableCell>
                            </StyledTableRow>);
                        })}
                        {props.bonusGroup ? <BonusGroupRow>
                            <StyledTableCell align="left" colSpan={3}>
                                <b>{props.bonusGroup.groupName}</b>
                            </StyledTableCell>
                            <StyledTableCell align="right">
                                <b>{convertSecondsToHoursAndMinutes(props.bonusGroup.addedSeconds)}</b>
                            </StyledTableCell>
                            <StyledTableCell align="right">
                                <b>Bonus od podstawy: {props.bonusGroup.bonusPercent}%</b>
                            </StyledTableCell>
                        </BonusGroupRow> : ""}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell>
                                <Typography
                                    variant="caption"
                                    color="text.secondary"
                                    noWrap
                                    sx={{flexGrow: 1}}
                                >
                                    Oczekiwany
                                    czas: {convertSecondsToHoursAndMinutes(props.expectedCurrentMonthActivitiesTotalSeconds)}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography
                                    variant="caption"
                                    color="text.secondary"
                                    noWrap
                                    sx={{flexGrow: 1}}
                                >
                                    Suma: {convertSecondsToHoursAndMinutes(activitiesTotalSeconds)}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography
                                    variant="caption"
                                    color="text.secondary"
                                    noWrap
                                    sx={{flexGrow: 1}}
                                >
                                    Suma: {convertSecondsToHoursAndMinutes(addedTotalSeconds)}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                {secondsLeft > 0 ? <Typography
                                        variant="caption"
                                        color="red"
                                        noWrap
                                        sx={{flexGrow: 1}}
                                    >
                                        Pozostało: {convertSecondsToHoursAndMinutes(secondsLeft)}
                                    </Typography> :
                                    secondsLeft === 0 ? <Typography
                                            variant="caption"
                                            color="green"
                                            noWrap
                                            sx={{flexGrow: 1}}
                                        >
                                            Suma: {props.bonusGroup ? convertSecondsToHoursAndMinutes(activitiesTotalSeconds + addedTotalSeconds + props.bonusGroup.addedSeconds) : convertSecondsToHoursAndMinutes(activitiesTotalSeconds + addedTotalSeconds)}
                                        </Typography> :
                                        <Typography
                                            variant="caption"
                                            color="red"
                                            noWrap
                                            sx={{flexGrow: 1}}
                                        >
                                            Nadmiarowo: {convertSecondsToHoursAndMinutes(-1 * secondsLeft)}
                                        </Typography>}

                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    )
}