import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {convertSecondsToHoursAndMinutes} from "../../TimeUtils";

interface ActivitiesTimeComponentProps {
    seconds: number
}

export default function ActivitiesTimeComponent(props: ActivitiesTimeComponentProps) {
    return (
        <React.Fragment>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
                width="100%"
            >
                <Box display="flex">
                    <Typography
                        variant="h4"
                        color="inherit"
                        noWrap
                    >
                        {convertSecondsToHoursAndMinutes(props.seconds)}
                    </Typography>
                </Box>
            </Box>
        </React.Fragment>
    );
}