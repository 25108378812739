import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import * as React from "react";
import {styled} from "@mui/material/styles";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import {ActivityPerMonth} from "../../model/ActivityPerMonth";
import {convertSecondsToHoursAndMinutes} from "../../TimeUtils";

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

interface ActivitiesMonthTableComponentProps {
    activitiesPerMonth: ActivityPerMonth[]
    height: string | number
}

export default function ActivitiesMonthTableComponent(props: ActivitiesMonthTableComponentProps) {
    return (
        <TableContainer component={Paper} style={{height: props.height}}>
            <Table sx={{minWidth: 700}} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Miesiąc</StyledTableCell>
                        <StyledTableCell align="right">Czas</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.activitiesPerMonth.map((row) => (
                        <StyledTableRow key={row.monthYear}>
                            <StyledTableCell>{row.monthYear}</StyledTableCell>
                            <StyledTableCell
                                align="right">{convertSecondsToHoursAndMinutes(row.timeSpentSeconds)}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}