import React, {useState} from 'react';
import './App.css';
import AppRouter from './AppRouter';
import {BrowserRouter} from "react-router-dom";
import NavigationPane from "./component/NavigationPane";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Divider from "@mui/material/Divider";
import {createTheme, styled, ThemeProvider} from "@mui/material/styles";
import Link from "@mui/material/Link";
import {AppBarProps as MuiAppBarProps} from "@mui/material/AppBar/AppBar";
import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import Container from "@mui/material/Container";
import {createNavigationValues} from "./NavigationValues";
import {TimesheetViewerSummary} from "./model/TimesheetViewerSummary";
import {BonusGroup, CurrentMonthGroup} from "./dashboard/component/CurrentMonthGroupsComponent";

function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://gitlab.com/agrzybowski">
                Adam Grzybowski
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

const defaultTheme = createTheme();

function App() {
    const [open, setOpen] = useState(false);
    const [summary, setSummary] = useState<TimesheetViewerSummary | null>(null);
    const [pageTitle, setPageTitle] = useState("");
    const [currentMonthGroups, setCurrentMonthGroups] = useState(new Map<string, CurrentMonthGroup>());
    const [bonusGroup, setBonusGroup] = useState<BonusGroup | null>(null);
    const handleGroupChange = (groups: Map<string, CurrentMonthGroup>) => {
        setCurrentMonthGroups(groups);
    }
    const handleBonusGroupChange = (newBonusGroup: BonusGroup | null) => {
        setBonusGroup(newBonusGroup);
    }
    const toggleDrawer = () => {
        setOpen(!open);
    };
    const dataCallback = (summary: TimesheetViewerSummary) => {
        setSummary(summary);
    }
    const changeTitle = (title: string) => {
        setPageTitle(title);
    }

    const navigationPaneValues = createNavigationValues(
        changeTitle,
        dataCallback,
        summary,
        currentMonthGroups,
        handleGroupChange,
        bonusGroup,
        handleBonusGroupChange);

    return (
        <BrowserRouter>
            <ThemeProvider theme={defaultTheme}>
                <Box sx={{display: 'flex'}}>
                    <CssBaseline/>
                    <AppBar position="absolute" open={open}>
                        <Toolbar
                            sx={{
                                pr: '24px', // keep right padding when drawer closed
                            }}
                        >
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={toggleDrawer}
                                sx={{
                                    marginRight: '36px',
                                    ...(open && {display: 'none'}),
                                }}
                            >
                                <MenuIcon/>
                            </IconButton>
                            <Typography
                                component="h1"
                                variant="h6"
                                color="inherit"
                                noWrap
                                sx={{flexGrow: 1}}
                            >
                                {pageTitle}
                            </Typography>
                            <Typography
                                component="h1"
                                variant="h5"
                                color="inherit"
                                noWrap
                                fontWeight="bold"
                                fontFamily="Arial Narrow, Arial, sans-serif"
                                sx={{flexGrow: 1}}
                                align={"right"}
                            >
                                Jira Timesheet Viewer
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Drawer variant="permanent" open={open}>
                        <Toolbar
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                px: [1],
                            }}
                        >
                            <IconButton onClick={toggleDrawer}>
                                <ChevronLeftIcon/>
                            </IconButton>
                        </Toolbar>
                        <Divider/>
                        <NavigationPane navigationPaneValues={navigationPaneValues}/>
                    </Drawer>
                    <Box
                        component="main"
                        sx={{
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'light'
                                    ? theme.palette.grey[100]
                                    : theme.palette.grey[900],
                            flexGrow: 1,
                            height: '100vh',
                            overflow: 'auto',
                        }}
                    >
                        <Toolbar/>
                        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
                            <AppRouter navigationPaneValues={navigationPaneValues}/>
                            <Copyright sx={{pt: 4}}/>
                        </Container>
                    </Box>
                </Box>
            </ThemeProvider>
        </BrowserRouter>
    );
}

export default App;
