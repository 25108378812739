import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import * as React from "react";
import {styled} from "@mui/material/styles";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import {Activity} from "../../model/Activity";
import {convertSecondsToHoursAndMinutes, formatIsoDateTimeToString} from "../../TimeUtils";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

interface ActivitiesTableComponentProps {
    activities: Activity[]
    height: string | number
}

export default function ActivitiesTableComponent(props: ActivitiesTableComponentProps) {
    return (
        <TableContainer component={Paper} style={{maxHeight: props.height}}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Zgłoszenie</StyledTableCell>
                        <StyledTableCell>Opis</StyledTableCell>
                        <StyledTableCell>Sprint</StyledTableCell>
                        <StyledTableCell>Data rozpoczęcia</StyledTableCell>
                        <StyledTableCell>Data zakończenia</StyledTableCell>
                        <StyledTableCell align="right">Czas</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.activities.map((row) => (
                        <StyledTableRow key={row.startDate}>
                            <StyledTableCell component="th" scope="row">
                                <a href={row.url}>{row.issue}</a>
                            </StyledTableCell>
                            <StyledTableCell>{row.description}</StyledTableCell>
                            <StyledTableCell>{row.sprint}</StyledTableCell>
                            <StyledTableCell>{formatIsoDateTimeToString(row.startDate)}</StyledTableCell>
                            <StyledTableCell>{formatIsoDateTimeToString(row.endDate)}</StyledTableCell>
                            <StyledTableCell align="right">{convertSecondsToHoursAndMinutes(row.timeSpentSeconds)}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}