import * as React from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DashboardPage from "./dashboard/DashboardPage";
import {AccessTime, Home, ListAlt, Sailing} from "@mui/icons-material";
import HoursPage from "./hours/HoursPage";
import ActivitiesPage from "./activities/ActivitiesPage";
import HolidaysPage from "./holidays/HolidaysPage";
import HomePage from "./HomePage";
import {TimesheetViewerSummary} from "./model/TimesheetViewerSummary";
import {BonusGroup, CurrentMonthGroup} from "./dashboard/component/CurrentMonthGroupsComponent";

export interface NavigationValue {
    path: string,
    text: string,
    icon: any,
    page: any
}

export function createNavigationValues(changeTitle: (title: string) => void,
                                       dataCallback: (summary: TimesheetViewerSummary) => void,
                                       summary: TimesheetViewerSummary | null,
                                       currentMonthGroups: Map<string, CurrentMonthGroup>,
                                       handleGroupsChange: (groups: Map<string, CurrentMonthGroup>) => void,
                                       bonusGroup: BonusGroup | null,
                                       handleBonusGroupChange: (newBonusGroup: BonusGroup | null) => void): NavigationValue[] {
    return [
        {
            path: "/",
            text: "Start",
            icon: <Home/>,
            page: <HomePage dataCallback={dataCallback} changeTitle={changeTitle}
                            dataRetrieved={summary !== null}/>
        },
        {
            path: "/dashboard",
            text: "Panel główny",
            icon: <DashboardIcon/>,
            page: <DashboardPage summary={summary} changeTitle={changeTitle} currentMonthGroups={currentMonthGroups}
                                 handleGroupsChange={handleGroupsChange} bonusGroup={bonusGroup} handleBonusGroupChange={handleBonusGroupChange}/>
        },
        {
            path: "/hours",
            text: "Bank godzin",
            icon: <AccessTime/>,
            page: <HoursPage hours={summary?.hours} changeTitle={changeTitle}/>
        },
        {
            path: "/activities",
            text: "Wszystkie aktywności",
            icon: <ListAlt/>,
            page: <ActivitiesPage activities={summary?.activities} changeTitle={changeTitle}/>
        },
        {
            path: "/holidays",
            text: "Dni wolne od pracy",
            icon: <Sailing/>,
            page: <HolidaysPage holidays={summary?.holidays} changeTitle={changeTitle}/>
        }
    ];
}