import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import * as React from "react";
import {styled} from "@mui/material/styles";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import {convertSecondsToHoursAndMinutes, formatIsoDateToString} from "../../TimeUtils";
import {DayOfWork} from "../../model/DayOfWork";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

interface DayOfWorkTableComponentProps {
    daysOfWork: DayOfWork[]
    height: number | string
}

export default function DayOfWorkTableComponent(props: DayOfWorkTableComponentProps) {
    return (
        <TableContainer component={Paper} style={{height: props.height}}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Data</StyledTableCell>
                        <StyledTableCell align="right">Łączny czas</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.daysOfWork.map((row) => (
                        <StyledTableRow key={row.date}>
                            <StyledTableCell component="th" scope="row">
                                {formatIsoDateToString(row.date)}
                            </StyledTableCell>
                            <StyledTableCell align="right">{convertSecondsToHoursAndMinutes(row.totalTimeSpentSeconds)}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}