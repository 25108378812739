import moment from "moment";
import {format, parseISO} from "date-fns";
import {pl} from "date-fns/locale";

export function convertSecondsToHoursAndMinutes(seconds: number): string {
    if (seconds >= 0) {
        return Math.floor(seconds / 3600) + "h " + Math.floor((seconds % 3600) / 60) + "m";
    } else {
        return "-" + Math.floor(seconds * -1 / 3600) + "h " + Math.floor((seconds * -1 % 3600) / 60) + "m";
    }
}

export function convertSecondsToHours(seconds: number): string {
    const hours = seconds / 3600;
    if (hours % 1 === 0) {
        return String(hours);
    } else {
        return hours.toFixed(2).replace(/\.?0+$/, '');
    }
}

export function calculateTimeBetweenDateAndTodayInDays(date: Date): number {
    return Math.abs(moment(date).diff(new Date(), 'days'));
}

export function formatIsoDateTimeToString(isoDate: string): string {
    return format(parseISO(isoDate), "dd-MM-yyyy (EEE) HH:mm", {locale: pl});
}

export function formatIsoDateToString(isoDate: string): string {
    return format(parseISO(isoDate), "dd-MM-yyyy (EEE)", {locale: pl});
}

export function convertHoursAndMinutesToSeconds(hoursAndMinutes: string): number {
    const regex = new RegExp("(?:([^ ]+)h)? ?(?:([^ ]+)m)?").exec(hoursAndMinutes);
    if (regex) {
        const hours = regex[1] ? parseInt(regex[1]) : 0;
        const minutes = regex[2] ? parseInt(regex[2]) : 0;
        return (hours * 3600) + (minutes * 60);
    }
    return 0;
}