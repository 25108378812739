import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    InputAdornment,
    TextField
} from "@mui/material";
import * as React from "react";
import {CurrentMonthGroup} from "./CurrentMonthGroupsComponent";
import {ChangeEvent, useState} from "react";

export interface CurrentMonthGroupsDialogProps {
    editGroup: CurrentMonthGroup | null,
    dialogTitle: string,
    dialogContentText?: string,
    dialogButtonText: string,
    dialogGroupLabel?: string,
    dialogInputAdornmentText?: string,
    dialogGroupPlaceholder?: string,
    dialogTimeLabel?: string,
    dialogTimePlaceholder?: string,
    dialogBonusPercentLabel?: string,
    dialogBonusPercentPlaceholder?: string,
    dialogCallback: (currentProps: CurrentMonthGroupsDialogProps, dialogGroupTextValue: string, dialogTimeTextValue: string, dialogPercentTextValue: string) => void,
    onDialogTimeTextChange?: (currentProps: CurrentMonthGroupsDialogProps, dialogTimeTextValue: string) => void
}

interface CurrentMonthGroupsDialogComponentProps {
    props: CurrentMonthGroupsDialogProps,
    onCancelButtonClick: () => void,
}

export default function CurrentMonthGroupsDialogComponent(props: CurrentMonthGroupsDialogComponentProps) {
    const [dialogGroupTextValue, setDialogGroupTextValue] = useState("");
    const [dialogTimeTextValue, setDialogTimeTextValue] = useState("");
    const [dialogBonusPercentTextValue, setDialogBonusPercentTextValue] = useState("");
    const [dialogGroupError, setDialogGroupError] = useState("");
    const [dialogTimeError, setDialogTimeError] = useState("");
    const [dialogBonusPercentError, setDialogBonusPercentError] = useState("");

    const onlyNumbersRegex = new RegExp("^[0-9]*$");

    const clearDialogParameters = () => {
        setDialogGroupTextValue("");
        setDialogTimeTextValue("");
        setDialogBonusPercentTextValue("");
        setDialogGroupError("");
        setDialogTimeError("");
        setDialogBonusPercentError("");
    }

    const onDialogGroupTextChange = (event: ChangeEvent<HTMLInputElement>) => {
        setDialogGroupError("");
        setDialogGroupTextValue(event.target.value);
    };

    const onDialogTimeTextValueChange = (event: ChangeEvent<HTMLInputElement>) => {
        setDialogTimeError("");
        setDialogTimeTextValue(event.target.value);
        if (props.props.onDialogTimeTextChange) {
            props.props.onDialogTimeTextChange(props.props, event.target.value);
        }
    };

    const onDialogBonusPercentTextValueChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (onlyNumbersRegex.test(event.target.value)) {
            setDialogBonusPercentError("");
            setDialogBonusPercentTextValue(event.target.value);
        }
    }

    const handleDialogKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            handleButtonClick();
        }
        if (event.key === 'Escape') {
            handleCancelButtonClick();
        }
    }

    const handleButtonClick = () => {
        let hasError = false;
        if (props.props.dialogGroupLabel && dialogGroupTextValue.length === 0) {
            setDialogGroupError("Nazwa grupy nie może być pusta!");
            hasError = true;
        }
        if (props.props.dialogTimeLabel && dialogTimeTextValue.length === 0) {
            setDialogTimeError("Wartość nie może być pusta!");
            hasError = true;
        }
        if (props.props.dialogBonusPercentLabel && dialogBonusPercentTextValue.length === 0) {
            setDialogBonusPercentError("Wartość nie może być pusta!");
            hasError = true;
        }
        if (!hasError) {
            props.props.dialogCallback(props.props, dialogGroupTextValue, dialogTimeTextValue, dialogBonusPercentTextValue);
            clearDialogParameters();
        }
    }

    const handleCancelButtonClick = () => {
        props.onCancelButtonClick();
        clearDialogParameters();
    }

    return (<Dialog open={!!props.props.editGroup} onKeyDown={handleDialogKeyDown}>
        <DialogTitle>{props.props.dialogTitle}</DialogTitle>
        <DialogContent>
            <DialogContentText>{props.props.dialogContentText}</DialogContentText>
            {props.props.dialogGroupLabel ? <TextField
                autoFocus
                margin="dense"
                label={props.props.dialogGroupLabel}
                fullWidth
                value={dialogGroupTextValue}
                onChange={onDialogGroupTextChange}
                placeholder={props.props.dialogGroupPlaceholder}
                error={!!dialogGroupError}
                helperText={dialogGroupError}
            /> : ""}
            {props.props.dialogTimeLabel ? <TextField
                autoFocus
                margin="dense"
                label={props.props.dialogTimeLabel}
                fullWidth
                value={dialogTimeTextValue}
                InputProps={{startAdornment: <InputAdornment position="start">{props.props.dialogInputAdornmentText}</InputAdornment>}}
                onChange={onDialogTimeTextValueChange}
                placeholder={props.props.dialogTimePlaceholder}
                error={!!dialogTimeError}
                helperText={dialogTimeError}
            /> : ""}
            {props.props.dialogBonusPercentLabel ?
                    <TextField
                        autoFocus
                        margin="dense"
                        label={props.props.dialogBonusPercentLabel}
                        fullWidth
                        value={dialogBonusPercentTextValue}
                        inputProps={{inputMode: 'numeric', style: {textAlign: 'right'}}}
                        InputProps={{endAdornment: <InputAdornment position="end">{props.props.dialogInputAdornmentText}</InputAdornment>}}
                        onChange={onDialogBonusPercentTextValueChange}
                        placeholder={props.props.dialogBonusPercentPlaceholder}
                        error={!!dialogBonusPercentError}
                        helperText={dialogBonusPercentError}
                    /> : ""}
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCancelButtonClick}>
                Anuluj
            </Button>
            <Button onClick={handleButtonClick}>
                {props.props.dialogButtonText}
            </Button>
        </DialogActions>
    </Dialog>);
}