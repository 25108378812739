import * as React from 'react';
import ActivitiesTableComponent from "./component/ActivitiesTableComponent";
import ActivitiesTimeComponent from "./component/ActivitiesTimeComponent";
import Grid from "@mui/material/Grid";
import {Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import ActivitiesMonthTableComponent from "./component/ActivitiesMonthTableComponent";
import {Activities} from "../model/Activities";
import NoDataComponent from "../component/NoDataComponent";
import {useEffect} from "react";

interface ActivitiesPageProps {
    activities: Activities | undefined,
    changeTitle: (title: string) => void
}

export default function ActivitiesPage(props: ActivitiesPageProps) {
    useEffect(() => {
        props.changeTitle("Wszystkie aktywności");
    });
    return props.activities ? createActivitiesPageWithData(props.activities) : <NoDataComponent/>
}

function createActivitiesPageWithData(activities: Activities) {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}  md={4} lg={4}>
                <Paper
                    sx={{
                        p: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 240
                    }}
                >
                    <Typography
                        variant="caption"
                        color="text.secondary"
                        noWrap
                        sx={{flexGrow: 1}}
                    >
                        Suma czasu aktywności
                    </Typography>
                    <ActivitiesTimeComponent seconds={activities.activitiesTotalSeconds}/>
                </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={8}>
                <ActivitiesMonthTableComponent activitiesPerMonth={activities.activitiesPerMonth} height={240}/>
            </Grid>
            <Grid item xs={12}>
                <ActivitiesTableComponent activities={activities.activities} height="auto"/>
            </Grid>
        </Grid>
    );
}
