import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {CompanyHoliday} from "../../model/CompanyHoliday";

function calculateTotalCompanyHolidays(companyHolidays: CompanyHoliday[]): number {
    return companyHolidays.reduce((total, companyHoliday) => total + companyHoliday.nonWeekendDaysCount, 0);
}

interface CompanyHolidaysComponentProps {
    companyHolidays: CompanyHoliday[]
}

export default function CompanyHolidaysComponent(props: CompanyHolidaysComponentProps) {
    return (
        <React.Fragment>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
                width="100%"
            >
                <Box display="flex">
                    <Typography
                        variant="h3"
                        color="inherit"
                        noWrap
                    >
                        {calculateTotalCompanyHolidays(props.companyHolidays)}
                    </Typography>
                </Box>
            </Box>
        </React.Fragment>
    );
}