import Typography from "@mui/material/Typography";
import {Cancel, CheckCircle, RemoveCircle} from "@mui/icons-material";
import React from "react";
import Box from "@mui/material/Box";
import {TimesheetStatistics} from "../../model/TimesheetStatistics";
import {calculateTimeBetweenDateAndTodayInDays} from "../../TimeUtils";
import {Grid} from "@mui/material";

function getIcon(ago: number) {
    if (ago === 0) {
        return <CheckCircle color="success"/>;
    } else if (ago > 0 && ago < 7) {
        return <RemoveCircle color="warning"/>;
    } else {
        return <Cancel color="error"/>;
    }
}

function getText(ago: number) {
    if (ago === 0) {
        return <Box>
            <Typography
                variant="body1"
                color="inherit"
                noWrap>
                Wpisy są aktualne. Data najnowszego wpisu jest co najmniej z <b>dzisiaj</b>.
            </Typography>
        </Box>;
    } else if (ago > 0 && ago < 7) {
        return <Typography
            variant="body1"
            color="inherit"
            noWrap>
            Wpisy są z ostatniego tygodnia. Data najnowszego wpisu jest sprzed <b>{ago} {ago === 1 ? "dnia" : "dni"}</b>.
        </Typography>;
    } else {
        return <Typography
            variant="body1"
            color="inherit"
            noWrap>
            Wpisy nie są aktualne. Data najnowszego wpisu jest sprzed <b>{ago} dni</b>.
        </Typography>;
    }
}

interface FileUpToDateComponentProps {
    timesheetStatistics: TimesheetStatistics
}

export default function FileUpToDateComponent(props: FileUpToDateComponentProps) {
    const daysBetweenLastActivityAndToday = calculateTimeBetweenDateAndTodayInDays(new Date(props.timesheetStatistics.newestActivityDate));
    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Typography
                            variant="body1"
                            color="inherit"
                            noWrap>
                            <b>{new Date(props.timesheetStatistics.oldestActivityDate).toLocaleDateString()}</b> - <b>{new Date(props.timesheetStatistics.newestActivityDate).toLocaleDateString()}</b>
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Box display="flex">
                            {getIcon(daysBetweenLastActivityAndToday)}
                        </Box>
                        <Box ml={1}>
                            {getText(daysBetweenLastActivityAndToday)}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}