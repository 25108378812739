import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Vacation} from "../../model/Vacation";

interface VacationComponentProps {
    vacations: Vacation[]
    vacationLimitDays: number
}

function calculateTotalUsedVacations(vacations: Vacation[]): number {
    return vacations.reduce((total, vacation) => total + vacation.nonWeekendDaysCount, 0);
}

export default function VacationComponent(props: VacationComponentProps) {
    return (
        <React.Fragment>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
                width="100%"
            >
                <Box display="flex">
                    <Typography
                        variant="h3"
                        color="inherit"
                        noWrap
                    >
                        {calculateTotalUsedVacations(props.vacations)}/{props.vacationLimitDays}
                    </Typography>
                </Box>
            </Box>
        </React.Fragment>
    );
}