import Grid from "@mui/material/Grid";
import {Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import HoursBalanceComponent from "./component/HoursBalanceComponent";
import * as React from "react";
import ShortTimeComponent from "./component/ShortTimeComponent";
import OvertimeComponent from "./component/OvertimeComponent";
import {Hours} from "../model/Hours";
import {convertSecondsToHoursAndMinutes} from "../TimeUtils";
import DayOfWorkTableComponent from "./component/DayOfWorkTableComponent";
import NoDataComponent from "../component/NoDataComponent";
import {useEffect} from "react";

interface HoursPageProps {
    hours: Hours | undefined,
    changeTitle: (title: string) => void
}

export default function HoursPage(props: HoursPageProps) {
    useEffect(() => {
        props.changeTitle("Bank godzin");
    });
    return props.hours ? createHoursPageWithData(props.hours) : <NoDataComponent/>;
}

function createHoursPageWithData(hours: Hours) {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={4}>
                <Paper
                    sx={{
                        p: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 120
                    }}
                >
                    <Typography
                        variant="caption"
                        color="text.secondary"
                        noWrap
                        sx={{flexGrow: 1}}
                    >
                        Bilans godzin
                    </Typography>
                    <HoursBalanceComponent seconds={hours.balanceSeconds}/>
                </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
                <Paper
                    sx={{
                        p: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 120
                    }}
                >
                    <Typography
                        variant="caption"
                        color="text.secondary"
                        noWrap
                        sx={{flexGrow: 1}}
                    >
                        Suma niedogodzin
                    </Typography>
                    <ShortTimeComponent text={convertSecondsToHoursAndMinutes(hours.shortTimeTotalSeconds)}/>
                </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
                <Paper
                    sx={{
                        p: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 120
                    }}
                >
                    <Typography
                        variant="caption"
                        color="text.secondary"
                        noWrap
                        sx={{flexGrow: 1}}
                    >
                        Suma nadgodzin
                    </Typography>
                    <OvertimeComponent text={convertSecondsToHoursAndMinutes(hours.overtimeTotalSeconds)}/>
                </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={6}>
                <Paper
                    sx={{
                        p: 1,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Typography
                        variant="caption"
                        color="text.secondary"
                        noWrap
                        sx={{flexGrow: 1}}
                    >
                        Wszystkie niedogodziny
                    </Typography>
                    <DayOfWorkTableComponent daysOfWork={hours.shortTimeDays} height="auto"/>
                </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={6}>
                <Paper
                    sx={{
                        p: 1,
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Typography
                        variant="caption"
                        color="text.secondary"
                        noWrap
                        sx={{flexGrow: 1}}
                    >
                        Wszystkie nadgodziny
                    </Typography>
                    <DayOfWorkTableComponent daysOfWork={hours.overtimeDays} height="auto"/>
                </Paper>
            </Grid>
        </Grid>
    );
}