import OvertimeTableComponent from "../../hours/component/OvertimeTableComponent";
import ShortTimeTableComponent from "../../hours/component/ShortTimeTableComponent";
import {OvertimeDayOfWork} from "../../model/OvertimeDayOfWork";
import {ShortTimeDayOfWork} from "../../model/ShortTimeDayOfWork";

interface DashboardTimeComponentProps {
    balanceSeconds: number,
    overtimes: OvertimeDayOfWork[],
    shortTimes: ShortTimeDayOfWork[]
}

export default function DashboardTimeComponent(props: DashboardTimeComponentProps) {
    return (
        <>
            {props.balanceSeconds >= 0 ? <OvertimeTableComponent rows={props.overtimes} height={240}/> : <ShortTimeTableComponent rows={props.shortTimes} height={240}/>}
        </>
    );
}