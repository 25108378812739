import React from "react";
import OvertimeComponent from "./OvertimeComponent";
import ShortTimeComponent from "./ShortTimeComponent";
import {convertSecondsToHoursAndMinutes} from "../../TimeUtils";

interface HoursBalanceComponentProps {
    seconds: number
}

export default function HoursBalanceComponent(props: HoursBalanceComponentProps) {
    return (<>{props.seconds >= 0 ? <OvertimeComponent text={convertSecondsToHoursAndMinutes(props.seconds)}/> : <ShortTimeComponent text={convertSecondsToHoursAndMinutes(props.seconds)}/>}</>)
}