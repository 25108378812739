import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {PublicHoliday} from "../../model/PublicHoliday";

function calculateTotalPublicHolidays(vacations: PublicHoliday[]): number {
    return vacations.reduce((total, publicHoliday) => total + publicHoliday.nonWeekendDaysCount, 0);
}

interface PublicHolidaysComponentProps {
    publicHolidays: PublicHoliday[]
}

export default function PublicHolidaysComponent(props: PublicHolidaysComponentProps) {
    return (
        <React.Fragment>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
                width="100%"
            >
                <Box display="flex">
                    <Typography
                        variant="h3"
                        color="inherit"
                        noWrap
                    >
                        {calculateTotalPublicHolidays(props.publicHolidays)}
                    </Typography>
                </Box>
            </Box>
        </React.Fragment>
    );
}